.label_title {
  margin-bottom: 5px;
  color: #444444;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  display: inline;
}

.select_inputs {
  background: #ffffff;
  opacity: 1;
  height: 48px;
  box-sizing: border-box;
  font: normal normal 300 14px/16px Arial, serif;
  letter-spacing: 0.1px;
  text-align: left;
}

.select_inputs_placeholder {
  background: #ffffff;
  height: 48px;
  box-sizing: border-box;
  font: normal normal 300 14px/16px Arial, serif;
  letter-spacing: 0.1px;
  text-align: left;
}

.select_inputs svg {
  color: #661c69;
}

.select_inputs .MuiOutlinedInput-input {
  padding: 11.5px 14px;
}
.select_inputs fieldset {
  border-radius: 6px;
  border: 1px solid #e4e9f2;
}
.select_inputs_error fieldset {
  border-radius: 6px;
  border: 1.6px solid #fd0000;
}

.select_style {
  background: #ffffff;
  opacity: 1;
  /* height: 48px; */
  box-sizing: border-box;
  font: normal normal 300 14px/16px Arial, serif;
  letter-spacing: 0.1px;
  text-align: left;
}

.menuitem_style {
  white-space: normal;
}

.otp_menuitem_style {
  white-space: normal;
}

.otp_menuitem_style:hover {
  background-color: #661c69;
  color: #ffffff;
}

.label_title span {
  color: #fd0000;
}

.error {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #fd0000;
  margin-top: 2px;
}

@media only screen and (max-width: 600px) {
  .label_title {
    margin-bottom: 10px;
    color: #444444;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
  }
}
