.requirement {
  height: 35px;
  display: flex;
  background-color: red;
  // align-items: center;
  margin-top: 50px;
}
.requirement p {
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
  font-family: sans-serif;
}
.invalid {
  color: red;
}
.valid {
  color: #7ac142;
  display: flex;
  flex-direction: row;

}

/* Ported from Alexander Haniotis' code here: https://codepen.io/haniotis/pen/KwvYLO */
.checkmark {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .2s cubic-bezier(0.650, 0.000, 0.450, 1.000) .5s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}