.logo {
  display: grid;
  position: absolute;
  justify-content: end;
  width: 100%;
  grid-template-columns: auto auto auto;
  justify-content: flex-end;
  gap: 20px;
}

@media only screen and (max-width: 1315px) {
  .logo > div > img {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .logo {
    gap: 0px;
  }

  .logo > div > img {
    display: 50%;
  }
}