@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

}

.donut {
  display: inline-block;
  /* border: 18px solid #F5821F; mockup */
  /* border: 18px solid #f4821c; gif colorpick */
  border: 5px solid #f39749;  
  border-left-color: #E37617;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: donut-spin 0.8s linear infinite;
}
