@font-face {
  font-family: 'TTNormsRegular';
  src: url('../src/fonts/TTNorms-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'TTNormsItalic';
  src: url('../src/fonts/TTNorms-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'TTNormsLight';
  src: url('../src/fonts/TTNorms-Light.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'TTNormsBold';
  src: url('../src/fonts/TTNorms-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'TTNormsBoldItalic';
  src: url('../src/fonts/TTNorms-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: Italic;
}
@font-face {
  font-family: 'TTNormsBlack';
  src: url('../src/fonts/TTNorms-Black.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'TTNormsBlackItalic';
  src: url('../src/fonts/TTNorms-BlackItalic.otf') format('opentype');
  font-weight: bold;
  font-style: Italic;
}

body {
  margin: 0;
  min-height: 100%;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  box-sizing: border-box;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.body-style {
  margin: 0;
  font-family: 'Arial', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  position: relative;
  height: unset;
  background-color: #FFFFFF !important;
  // background: #f8f8f8;
}

.body-style:after {
  content: '';
  display: block;
  height: 50px;
}

.vouched-body-style {
  margin: 0;
  font-family: 'Arial', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  position: relative;
  height: unset;
  background-color: #fff !important;
}

.handoff-title {
  margin-bottom: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.checkbox-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 7px;
  /* display: inline-block; */
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 12px;
}

/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.checkbox-label input:checked ~ .checkbox-custom::before {
  left: 0px;
  top: 0px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity: 0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}
.checkbox-label .checkbox-custom {
  display: inline-block;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  border: 2px solid rgba(0, 0, 0, 0.54);
}

.checkbox-label input:focus ~ .checkbox-custom {
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid #f5821f;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid rgba(0, 0, 0, 0.54);
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: '';
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #009bff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 10px;
  top: -13px;
  width: 6px;
  height: 23px;
  border: solid #f5821f;
  border-width: 0 2.5px 2.5px 0;
  background-color: transparent;
  border-radius: 0;
}

.MuiStepLabel-label.MuiStepLabel-active {
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: medium !important;
  font-size: 16px !important;
  line-height: 18px !important;
  font-family: Arial, serif  !important;
  letter-spacing: 0.11px !important;
  color: #661c69 !important;
  opacity: 1 !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: medium !important;
  font-size: 16px !important;
  line-height: 18px !important;
  font-family: Arial, serif  !important;
  letter-spacing: 0.11px !important;
  color: #661c69 !important;
  opacity: 1 !important;
}

.MuiStepLabel-label {
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: medium !important;
  font-size: 16px !important;
  line-height: 18px !important;
  font-family: Arial, serif  !important;
  letter-spacing: 0.11px !important;
  color: #555858 !important;
  opacity: 1 !important;
  margin-top: 12px !important;
}

.MuiInputLabel-outlined {
  transform: translate(6px, 4px) scale(1);
}

.MuiInputLabel-outlined.MuiInputLabel-shrink{
  transform: translate(6px, -6px) scale(0.75);
}

.MuiFormLabel-root.Mui-focused{
  color:black;
}

.MuiStep-horizontal {
  padding: 0 !important;
}

.custom-connector {
  width: 99%;
  height: 4px;
  background: #575159;
  position: absolute;
  top: 15px;
}

@media screen and (max-width: 650px) {
  .custom-connector {
    top: 13px;
  }
}

.MuiStepLabel-label.MuiStepLabel-completed > div.custom-connector {
  background: #661c69 !important;
}

.MuiStepLabel-label.MuiStepLabel-active > div.custom-connector {
  background: #661c69 !important;
}

.MuiStepConnector-lineHorizontal {
  display: none !important;
}
.personal_mo_number .MuiOutlinedInput-input {
  background: #f2f2f2;
  text-align: center;
}
.personal_mo_number fieldset.MuiOutlinedInput-notchedOutline {
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  display: none;
}

.dob-select fieldset.MuiOutlinedInput-notchedOutline {
  background: rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box;
  border: 0.51px solid #555858;
  border-right: 0;
  opacity: 1;
  border-left: 0;
  border-radius: 0;
}

.dob-select-day fieldset.MuiOutlinedInput-notchedOutline {
  border-radius: 3px 0 0 3px;
  background: rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box;
  border: 0.51px solid #555858;
  border-right: 0;
  opacity: 1;
}

.dob-select-datepicker {
  justify-content: flex-end;
  align-items: center;
  /* background: rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box !important; */
  background: rgba(85, 88, 88, 0.04) 0% 0% no-repeat padding-box;
  border: 0.51px solid #555858;
  border-radius: 0 3px 3px 0;
  border-left: 0;
  opacity: 1;
}

.dob-select-datepicker .MuiGrid-item {
  margin-right: 14px;
}

.date_dob .MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 20px;
  padding-left: 15px;
}

.month_dob .MuiSelect-outlined.MuiSelect-outlined {
  padding-left: 0;
}

.formData :nth-child(2) div:nth-child(2) {
  width: 50%;
}

@media screen and (max-width: 320px) {
  .MuiSelect-iconOutlined {
    right: 0;
  }
}

@media screen and (max-width: 650px) {
  .MuiStepLabel-label.MuiStepLabel-active {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .MuiStepLabel-label.MuiStepLabel-completed {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .MuiStepLabel-label {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}

/* .vouched .handoff {
  width: 220px !important;
  margin-right: 10px;
}

.vouched canvas {
  width: 205px !important;
  height: 205px !important;
} */

#screen1-back-button {
  background-color: #661c69;
  color: #ffffff;
  width: 86px;
  height: 45px;
  // font: normal normal normal 18px/21px Arial;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  text-transform: none;
  border: 0.5px solid #661c69;
  border-radius: 7px;
  display: none;
  float: right;
}

#screen1-back-button:hover {
  background-color: #661c69;
}

.note-text {
  font-size: 28px !important;
}

.vouched {
  background-color: #fff;
  height: 100%;
  position: unset;
  box-sizing: border-box;
}

.vouched-description {
  margin: 0 20px;
  width: unset !important;
  max-width: 700px;
}

#vouched_handoffLink {
  font-weight: 600;
}

.vouched_start_not-selected {
  fill: #707070;
}
/* #vouchedVerboseIDDiv{
  background-color: #f5821f !important;
} */
.start_selected {
  fill: #661c69;
}

#vouched_textArea {
  color: #661c69;
  font-weight: 600;
}

#vouched_surveyButton {
  background-color: #661c69 !important;
  color: white;
}

#vouched_surveyButton.disabled {
  color: rgba(255, 255, 255, 0.6) !important;
}

.dob-input-adjust .MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 15px;
  text-align: center;
  @media (max-width: 350px) {
    padding-right: 10px;
  }
}
.dob-input-adjust .MuiSelect-iconOutlined {
  left: 70%;
}

/* #idScreenButtons {
  position: unset
} */
#idScreenButtons .vouched_buttonText {
  border-radius: 7px !important;
}

.product-page input[type='radio'] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #661c99;
  border-radius: 50%;
  /* outline:none; */
}
.product-page input[type='radio']:before {
  content: '';
  display: block;
  width: 52%;
  height: 55%;
  margin: 20% 23%;
  border-radius: 50%;
}
.product-page .MuiRadio-colorSecondary.Mui-checked {
  color: #661c99;
}
/* .product-page .MuiSvgIcon-root{
  width: 0.8em;
  height: 0.8em;
} */
.product-page input[type='radio']:checked:before {
  background: #661c99;
  margin: 24%;
}

.anchor-style a {
  color: #661c99 !important;
  text-decoration: none !important;
}

// new Ui Changes
.info-wrapper {
  h4:nth-of-type(1) {
    margin: 10px 0 15px;
  }
  h4:nth-of-type(2) {
    color: #f5821f;
  }
}

.form {
  margin: 22px 0;
  border-radius: 5px;
  padding: 10px 40px 35px 40px;
  background: #fdfdfd;
  border: 0.25px solid #707070;
  @media (max-width: 440px) {
    padding: 10px 20px 35px;
  }
}

.error-message {
  // font: normal normal normal 14px/16px Arial;
  letter-spacing: 0.1px;
  color: #df0000;
  opacity: 1;
}

.select-icon {
  color: '#661C69';
  opacity: 1;
  @media (max-width: 340px) {
    right: 0;
  }
}

.btn-wrapper {
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 0 15px;
}

.msg-text {
  color: #f5821f;
  font-size: 14px;
  margin: 25px 0 10px;
  line-height: 16px;
  width: 250px;
}

.checkbox-label-style {
  margin-left: 10px;
  text-align: left;
  // font: 'normal normal 300 16px/18px Arial';
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.11px;
  color: #000000;
  opacity: 1;
}

.checkbox-content-position {
  display: 'block';
  position: 'relative';
  top: '-22px';
  left: '32px';
  width: 'fit-content';
  margin-right: '30px';
}

.MuiInputLabel-asterisk {
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 22px;
  font-family: Arial, serif;
  letter-spacing: 0.15px;
  color: #e37617;
  opacity: 1;
}

.otp-time {
  color: #e37617;
  margin-top: 20px;
  font-size: 16px;
  display: inline-block;
}

footer {
  // padding: 12px 18px;
  background: #f5f5f5;
  opacity: 0.85;
  // font: normal normal normal 12px/15px Arial;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.08px;
  color: #000000;
  text-align: center;
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
}

.MuiButton-text:hover {
  background-color: inherit;
}

.MuiButton-contained.Mui-disabled {
  color: white;
  box-shadow: none;
  background: #661c69;
  // font: normal normal bold 16px/18px Arial;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  opacity: 0.6;
}

.border-card {
  padding: 22px 20px;
  background: #f4f4f42b 0% 0% no-repeat padding-box;
  border: 0.25px solid #55585847;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0 20px 0;
}

.MuiInputBase-input.Mui-disabled {
  background: #f2edf2;
}

@media (min-width: 991px) and (max-width: 1360px) {
  .common-padding-container {
    padding: 0 40px;
  }
}

.resend-otp {
  letter-spacing: 0.1px;
  opacity: 1;
  position: relative;
  bottom: -2px;
  left: 0;
  font-weight: bold;
  padding: 12px 5px;
  min-height: auto;
  height: 0;
  span {
    display: inline-block;
    line-height: 1;
  }
}

.forgot-otp-time {
  // font: normal normal bold 13px/18px Arial;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-top: 4px;
}

.dash-line {
  border-top-style: dashed;
  color: #707070;
  width: 100%;
  border-width: 2px;
  margin: 20px 0 0 0px;
}

.otp-message {
  // font: normal normal normal 14px/18px Arial;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin: 30px 0 0;
}

.setting-menu {
  position: absolute;
  background: #fff;
  top: 30px;
  border: 1px solid #707070;
  width: 135px;
  right: 0;
  font-size: 15px;
  img {
    color: #e37617;
  }
  span {
    color: #661c69;
  }
}

svg:focus {
  outline: inherit;
}

.table-styling {
  padding: 0;
  thead {
    background: #e37617;
    // border: 1px solid #e37617;
    height: 40px;
    tr {
      th {
        color: #fff;
      }
      th:first-child {
        border-radius: 5px 0 0 0;
      }
      th:last-child {
        border-radius: 0 5px 0 0;
      }
    }
  }
  tbody {
    tr {
      td {
        height: 40px;
        padding: 0px 24px 0px 16px;
        border: 0px solid;
      }
    }
    tr:nth-child(even) {
      background: #5558580f;
    }
  }
}
