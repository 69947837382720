.inputField {
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  padding: 0;
}
.loaderStyle {
  height: auto !important;
}
.bordercolor {
  border-color: transparent;
}
.backgroundcolor {
  color: #661C69;
  background-color: #fff;
  height: 58px;
  width: 60px;
  margin-right: 20px;
  border-bottom: 2px solid #661C69;
  border-radius: 0px;
}

.backgroundcolor fieldset {
  border-color: transparent !important;
}
.backgroundcolor:hover fieldset {
  border-color: transparent !important;
}
.backgroundcolor .Mui-focused fieldset {
  border-color: transparent !important;
}
.backgroundcolor2 {
  color: #661C69;
  background-color: #fff;
  height: 58px;
  width: 60px;
  margin-right: 20px;
  border-bottom: 2px solid #661C69;
  border-radius: 0px;
}
.inputRoot {
  height: 58px;
  width: 60px;
  margin-right: 20px;
  /* background: #eceaea 0% 0% no-repeat padding-box;
  border-radius: 50%; */
  border-bottom: 2px solid #D0D0D0;
}
.inputRoot fieldset {
  border-color: transparent !important;
}
.inputRoot:hover fieldset {
  border-color: transparent !important;
}
.inputRoot .Mui-focused fieldset {
  border-color: transparent !important;
}
.field6Input {
  height: 58px;
  width: 60px;
  border-bottom: 2px solid #d0d0d0;
  margin-right: 20px;
}
.field6Input fieldset {
  border-color: transparent !important;
}
.field6Input:hover fieldset {
  border-color: transparent !important;
}
.field6Input .Mui-focused fieldset {
  border-color: transparent !important;
}
.resend {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #661c69;
  text-align: center;
  padding: 7px 0;
  text-transform: none;
  text-decoration: underline;
}
.forgot {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
  text-align: center;
  padding: 5px 0;
}

.belowDiv {
  display: inline-block;
  width: 100%;
}

@media only screen and (max-width: 550px) {
  .inputRoot {
    height: 50px;
    width: 50px;
    margin-right: 17px;
    /* background: #eceaea 0% 0% no-repeat padding-box;
    border-radius: 50%; */
    border-bottom: 2px solid #D0D0D0;
  }
  .field6Input {
    height: 50px;
    width: 50px;
    margin-right: 0;
    /* background: #eceaea 0% 0% no-repeat padding-box;
    border-radius: 50%; */
    border-bottom: 2px solid #D0D0D0;
  }
  .backgroundcolor {
    color: #661C69;
    background-color: #fff;
    height: 50px;
    width: 50px;
    margin-right: 17px;
    /* border-radius: 50%; */
  }
  
  .backgroundcolor fieldset {
    border-color: transparent !important;
  }
  .backgroundcolor:hover fieldset {
    border-color: transparent !important;
  }
  .backgroundcolor .Mui-focused fieldset {
    border-color: transparent !important;
  }
  .backgroundcolor2 {
    color: #661C69;
    background-color: #fff;
    height: 50px;
    width: 50px;
    margin-right: 0;
    /* border-radius: 50%; */
  }
  .belowDiv {
    display: inline-block;
    width: 100%;
  }
}
